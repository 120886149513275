import classes from './Questions.module.css';
import React, { useEffect } from 'react';
import QuestionCard from './QuestionCard/QuestionCard';
import { useLocation } from 'react-router';
const Questions = (props) => {
  const { search } = useLocation();
  const {smoothScroll} = props;
  useEffect(() => {
    if (search) {
      smoothScroll(search.substring(1));
    }
  }, [search, smoothScroll]);
  const content = props.questions.map((question, i) => {
    const url = props.titleToUrl(question.title);
    return (
      <QuestionCard
        key={question + i}
        question={question}
        index={i}
        questionUrl={url}
        search={search}
      />
    );
  });
  return <div className={classes.Questions}>{content}</div>;
};

export default Questions;

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SET_CURRENT_CLINIC = 'SET_CURRENT_CLINIC';

export const FETCH_MEMBERS_START = 'FETCH_MEMBERS_START';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAIL = 'FETCH_MEMBERS_FAIL';

export const JOIN_CLINIC_START = 'JOIN_CLINIC_START';
export const JOIN_CLINIC_SUCCESS = 'JOIN_CLINIC_SUCCESS';
export const JOIN_CLINIC_FAIL = 'JOIN_CLINIC_FAIL';

export const ADD_CLINIC = 'ADD_CLINIC';

export const CREATE_STAFF_START = 'CREATE_STAFF_START';
export const CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS';
export const CREATE_STAFF_FAIL = 'CREATE_STAFF_FAIL';

export const EDIT_STAFF_START = 'EDIT_STAFF_START';
export const EDIT_STAFF_SUCCESS = 'EDIT_STAFF_SUCCESS';
export const EDIT_STAFF_FAIL = 'EDIT_STAFF_FAIL';

export const DELETE_STAFF_START = 'DELETE_STAFF_START';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAIL = 'DELETE_STAFF_FAIL';

export const ADD_STAFF_START = 'ADD_STAFF_START';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'ADD_STAFF_FAIL';

export const KICK_STAFF_START = 'KICK_STAFF_START';
export const KICK_STAFF_SUCCESS = 'KICK_STAFF_SUCCESS';
export const KICK_STAFF_FAIL = 'KICK_STAFF_FAIL';

export const FETCH_SUPERVISIONS_START = 'FETCH_SUPERVISIONS_START';
export const FETCH_SUPERVISIONS_SUCCESS = 'FETCH_SUPERVISIONS_SUCCESS';
export const FETCH_SUPERVISIONS_FAIL = 'FETCH_SUPERVISIONS_FAIL';

export const CLEAR_SUPERVISIONS = 'CLEAR_SUPERVISIONS';

export const FETCH_SOUP_START = 'FETCH_SOUP_START';
export const FETCH_SOUP_SUCCESS = 'FETCH_SOUP_SUCCESS';
export const FETCH_SOUP_FAIL = 'FETCH_SOUP_FAIL';

export const DELETE_SOUP_START = 'DELETE_SOUP_START';
export const DELETE_SOUP_SUCCESS = 'DELETE_SOUP_SUCCESS';
export const DELETE_SOUP_FAIL = 'DELETE_SOUP_FAIL';

export const ADD_SOUP_START = 'ADD_SOUP_START';
export const ADD_SOUP_SUCCESS = 'ADD_SOUP_SUCCESS';
export const ADD_SOUP_FAIL = 'ADD_SOUP_FAIL';

// Request Status Feedback
export const REQUEST_STATUS_SUCCESS = 'REQUEST_STATUS_SUCCESS';
export const REQUEST_STATUS_FAILURE = 'REQUEST_STATUS_FAILURE';
export const REQUEST_STATUS_CLEAR = 'REQUEST_STATUS_CLEAR';

//Update roster
export const UPDATE_ROSTER = 'UPDATE_ROSTER';

//Delete InProgress soup
export const DELETE_IN_PROGRESS_SOUP_START = 'DELETE_IN_PROGRESS_SOUP_START';
export const DELETE_IN_PROGRESS_SOUP_SUCCESS =
  'DELETE_IN_PROGRESS_SOUP_SUCCESS';
export const DELETE_IN_PROGRESS_SOUP_FAIL = 'DELETE_IN_PROGRESS_SOUP_FAIL';

//Approve Member - auth.js actions/reducer
export const APPROVE_JOIN_MEMBER_START = 'APPROVE_JOIN_MEMBER_START';
export const APPROVE_JOIN_MEMBER_SUCCESS = 'APPROVE_JOIN_MEMBER_SUCCESS';
export const APPROVE_JOIN_MEMBER_FAIL = 'APPROVE_JOIN_MEMBER_FAIL';

//Approve Member - auth.js action / allmembers.js reducer
export const ADD_APPROVED_JOIN_MEMBER = 'ADD_APPROVED_JOIN_MEMBER';

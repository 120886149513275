import React from 'react';
import classes from './TableOfContents.module.css';
import { Link } from 'react-router-dom';
const TableOfContext = (props) => {
  return (
    <section className={classes.TableOfContents}>
      <h3>Table of Contents</h3>
      <ul>
        {props.questions.map((q, i) => {
          const query = props.titleToUrl(q.title);
          return (
            <Link key={i} to={{ search: query }}>
              <li onClick={() => props.smoothScroll(query)}>{q.title}</li>
            </Link>
          );
        })}
      </ul>
    </section>
  );
};

export default TableOfContext;

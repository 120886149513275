import React from 'react';
import classes from './UsefulLinks.module.css';
import { Link } from 'react-router-dom';

const UsefulLinks = ({ links }) => {
  if (links.length > 0) {
    return (
      <ul className={classes.UsefulLinks}>
        <span>Go to:</span>
        {links.map((link, i) => {
          return (
            <li key={i}>
              <Link to={link.path}>{link.title}</Link>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return null;
  }
};

export default UsefulLinks;

import React, {useState, useEffect} from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    padding: '40',
    fontSize: '11',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerData: {
    width: '60%',
  },
  headerLogo: {},
  table: {
    marginTop: '20',
    borderTop: '1 solid #000',
  },
  tableGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    display: 'block',
    borderBottom: '1 solid #000',
    borderRight: '1 solid #000',
    width: '100%',
    height: '100%',
    padding: 3,
  },
  tableHeading: {
    fontSize: '14',
    fontWeight: 'semibold',
    borderBottom: '1 solid #000',
    borderRight: '1 solid #000',
    borderLeft: '1 solid #000',
    padding: '3',
  },
});

const HistoryPDF = ({ soup, start, end, soupSections }) => {
  const [sectionConfig, setSectionConfig] = useState([]);
  const {json} = soup;
  useEffect(()=> {
    const sectionConfig = [
      {
        name: 'starting',
        heading: `Session Set-Up - Score: ${soup.starting}`,
      },
      {
        name: 'main',
        heading: `Conducting the Session - Score: ${soup.conducting}`,
      },
      {
        name: 'ending',
        heading: `Ending the Session - Score: ${soup.ending}`,
      },
      {
        name: 'additional',
        heading: 'Additional Metrics',
      },
      {
        name: 'audit',
        heading: 'BCBA Treatment Note Audit',
      },
    ];
    const filteredSections = sectionConfig.filter((section)=>soupSections.includes(section.name));
    setSectionConfig(filteredSections);
  },[json])
  const Question = (q, i) => {
    if (q.value === 'NA') {
      return null;
    }
    let answer;

    switch (q.value) {
      case 'Y':
        answer = 'Yes';
        break;
      case 'A':
        answer = 'Almost';
        break;
      case 'N':
        answer = 'No';
        break;
      case 'Yes':
        answer = 'Yes';
        break;
      case 'No':
        answer = 'No';
        break;
      case 'E':
        answer = 'Excellent';
        break;
      case 'S':
        answer = 'Satisfactory';
        break;
      case 'NI':
        answer = 'Needs improvement';
        break;
      case '':
      case 'NA':
        answer = 'N/A';
        break;
      default:
        answer = '';
    }

    return (
      <React.Fragment key={q.value + i}>
        <View
          wrap={false}
          style={[styles.tableGroup, { borderLeft: '1 solid #000' }]}
        >
          <Text style={[styles.tableCell, { width: '55%' }]} wrap={false}>
            {q.elementConfig.question}
          </Text>
          <Text
            style={[styles.tableCell, { width: '15%', textAlign: 'center' }]}
            wrap={false}
          >
            {answer}
          </Text>
          <Text style={[styles.tableCell, { width: '30%' }]} wrap={false}>
            {q.noteValue}
          </Text>
        </View>
      </React.Fragment>
    );
  };
  
  const ColumnHeadings = () => {
    return (
      <View
        style={[
          styles.tableGroup,
          {
            textAlign: 'center',
            borderLeft: '1 solid #000',
          },
        ]}
        fixed
      >
        <Text style={[styles.tableCell, { width: '55%' }]}>
          Therapist Responses
        </Text>
        <Text style={[styles.tableCell, { width: '15%' }]}>Score</Text>
        <Text style={[styles.tableCell, { width: '30%' }]}>Feedback</Text>
      </View>
    );
  };
  
  const Section = ({ heading, section }) => {
    return (
      <View>
        <View style={styles.tableHeading}>
          <Text>{heading}</Text>
        </View>
        {Object.keys(soup.json[section]).map((key, i) => {
          return Question(soup.json[section][`${key}`], i);
        })}
      </View>
    );
  };
  
  return (
    <Document>
      <Page
        size="A4"
        style={styles.body}
      >
        <View>
          <View style={styles.header} fixed>
            <View style={styles.headerData}>
              <Text>Therapist: {soup.staff_member_id}</Text>
              <Text>Supervisor: {soup.supervisor_id}</Text>
              <Text>Date: {soup.date}</Text>
              <Text>
                Start: {start} End: {end}
              </Text>
            </View>
            <View style={styles.headerLogo}>
              <Text>Company:</Text>
            </View>
          </View>
          <View style={styles.table}>
            <ColumnHeadings />
            {console.log(sectionConfig)}
            {sectionConfig.map((section, i) => {

              return (
                <Section
                  key={section.name + i}
                  section={section.name}
                  heading={section.heading}
                />
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default HistoryPDF;

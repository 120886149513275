import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import resources from './qa';
import classes from './Faq.module.css';
import Topics from './Topics/Topics';
import Topic from './Topics/Topic/Topic';
import { Route, Switch } from 'react-router';
const Faq = (props) => {
  const { path } = useRouteMatch();
  const [topics] = useState(resources.topics);
  return (
    //render topics from qa
    <div className={classes.Faq}>
      <h1>Frequently Asked Questions</h1>

      <Switch>
        <Route path={`${path}/:topicId`} component={Topic} />
        <Route path="/faq">
          <Topics topics={topics} />
        </Route>
      </Switch>
    </div>
  );
};

export default Faq;

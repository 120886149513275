import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import classes from './PendingMembers.module.css';
import StaffCard from '../../../components/StaffCard/StaffCard';

const PendingMembers = (props) => {
  return (
    <div className={classes.PendingMembers}>
      <h2>Pending Members:</h2>
      {props.members.map((member, i) => {
        const supervisorDataFormat = {
          id: member.id,
          name: member.name,
          email: member.email,
        };
        return (
          <StaffCard
            key={i}
            name={member.name}
            staffId={member.member_id}
            email={member.email}
            token={props.token}
            handleApproveMember={props.onApproveJoinMember}
            requestId={member.id}
            clinicId={props.currentClinicId}
            memberData={supervisorDataFormat}
            pending
          />
        );
      })}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onApproveJoinMember: (paramObj) =>
      dispatch(actions.approveJoinMember(paramObj)),
  };
};

export default connect(null, mapDispatchToProps)(PendingMembers);

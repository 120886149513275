import React from 'react';
import classes from './TopicCard.module.css';
import { Link } from 'react-router-dom';

const TopicCard = ({ children, path }) => {
  return (
    <div className={classes.TopicCard}>
      <Link to={path}>{children}</Link>
    </div>
  );
};

export default TopicCard;

import React, { useEffect, useState } from 'react';
import questionsData from '../../qa';

import Button from '../../../../UI/Button/Button';

import TableOfContents from './TableOfContents/TableOfContents';
import Questions from './Questions/Questions';
import { Link } from 'react-router-dom';
import classes from './Topic.module.css';

const Topic = (props) => {
  const [resources] = useState(questionsData.topics);
  const [topicIndex, setTopicIndex] = useState(0);
  const [questions, setQuestions] = useState(null);

  const {topicId} = props.match.params;
  useEffect(() => {
    const topicIndex = resources.findIndex((topic) => topic.id === topicId);
    const questions = [...resources[topicIndex].questions];
    setTopicIndex(topicIndex);
    setQuestions(questions);
  }, [resources, topicId]);
  const titleToUrl = (string) => {
    return string
      .toLowerCase()
      .replace(/[.,/#!$%^&*;:{}=\-_`~()"?]/g, '')
      .split(' ')
      .join('-');
  };
  const smoothScroll = (query) => {
    const questionIndex = questions.findIndex(
      (question) => titleToUrl(question.title) === query
    );
    const target = document.querySelector(
      `[data-questionid="${questionIndex}"]`
    );
    window.setTimeout(() => {
      target.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }, 1);
  };
  return (
    <div className={classes.Topic}>
      <Link to={{ pathname: '/faq' }}>
        <Button type="button" btnType="Transparent">
          back
        </Button>
      </Link>
      <h2>{resources[topicIndex].title}</h2>
      {questions ? (
        <React.Fragment>
          <TableOfContents
            questions={questions}
            titleToUrl={titleToUrl}
            smoothScroll={smoothScroll}
          />
          <Questions
            questions={questions}
            titleToUrl={titleToUrl}
            smoothScroll={smoothScroll}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Topic;

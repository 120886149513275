import React, { useState, useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import classes from './QuestionCard.module.css';
import { Link } from 'react-router-dom';
import UsefulLinks from '../../../../UsefulLinks/UsefulLinks';

const QuestionCard = ({ question, index, questionUrl, search }) => {
  const [active, setActive] = useState(false);
  const [redirect, setRedirect] = useState(false);
  let { url } = useRouteMatch();

  useEffect(() => {
    if (search === '?' + questionUrl) {
      setActive(true);
      setRedirect(false);
    } else {
      setActive(false);
    }
  }, [search, questionUrl]);
  const handleClick = () => {
    if (active) {
      setActive(false);
      setRedirect(true);
    } else {
      setActive(true);
      setRedirect(false);
    }
  };
  return (
    <div
      className={classes.QuestionCard}
      onClick={handleClick}
      data-questionid={index}
    >
      <Link to={{ search: questionUrl }}>
        <div>
          <h3>{question.title}</h3>
          <span className={active ? classes.Rotate : null}></span>
        </div>
      </Link>

      {active ? <p>{question.answer}</p> : null}
      {active ? (
        <UsefulLinks links={question.links ? question.links : []} />
      ) : null}
      {redirect ? <Redirect to={{ pathname: `${url}` }} /> : null}
    </div>
  );
};

export default QuestionCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './NewClinic.module.css';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
class NewClinic extends Component {
  state = {
    controls: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
        },
        value: '',

        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
        label: 'Email',
      },
    },
    isJoin: false,
    showForm: false,
    showNotice: false,
  };
  showCreateFormHandler = () => {
    const updatedControls = {
      ...this.state.controls,
      name: {
        ...this.state.controls.name,
        label: "Your clinic's name",
      },
    };
    this.setState((prevState) => {
      return {
        controls: updatedControls,
        isJoin: false,
        showForm: !prevState.showForm,
      };
    });
  };
  showJoinFormHandler = () => {
    const updatedControls = {
      ...this.state.controls,
      name: {
        ...this.state.controls.name,
        label: 'Join Clinic (by ID)',
      },
    };
    this.setState((prevState) => {
      return {
        controls: updatedControls,
        isJoin: true,
        showForm: !prevState.showForm,
      };
    });
  };
  inputChangedHandler = (event) => {
    const updatedControls = {
      ...this.state.controls,
      name: {
        ...this.state.controls.name,
        value: event.target.value,
      },
    };
    this.setState({ controls: updatedControls });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.props.onJoin(
      this.state.isJoin,
      this.state.controls.name.value,
      this.props.token
    );
    // this.props.history.push('/');
    this.setState({ showNotice: true });
  };
  handleGoBack = () => {
    this.setState((prevState) => {
      return {
        showForm: !prevState.showForm,
      };
    });
  };
  render() {
    let form = null;
    if (this.state.showForm) {
      form = (
        <form
          onSubmit={(e) => this.handleFormSubmit(e)}
          className={classes.Form}
        >
          <Input
            elementType={this.state.controls.name.elementType}
            elementConfig={this.state.controls.name.elementConfig}
            value={this.state.controls.name.value}
            changed={(e) => this.inputChangedHandler(e)}
            shouldValidate={this.state.controls.name.validation}
            invalid={this.state.controls.name.valid}
            touched={this.state.controls.name.touched}
            label={this.state.controls.name.label}
          />
          <Button type="submit">Submit</Button>
          <Button type="button" clicked={this.handleGoBack}>
            Back
          </Button>
        </form>
      );
    }
    let error;
    if (this.props.error) {
      switch (this.props.error) {
        case 'ALREADY_IN_CLINIC':
          error = (
            <React.Fragment>
              <h3>Wait a minute!</h3>
              <p>You are already in this clinic.</p>
              <ul>
                <span>What can you do:</span>
                <li>Double check toggle menu on the left.</li>
                <li>Ask clinic's administrator if you have been approved.</li>
              </ul>
            </React.Fragment>
          );
          break;
        default:
          error = (
            <React.Fragment>
              <h3>Oh no! Something went wrong.</h3>
              <ul>
                <span>What can you do:</span>
                <li>
                  Make sure you entered correct Clinic's ID and try again.
                </li>
              </ul>
            </React.Fragment>
          );
      }
    }

    return (
      <React.Fragment>
        <div className={classes.NewClinic}>
          {this.state.showNotice && this.state.isJoin ? (
            <Modal show={this.state.showNotice}>
              <div className={classes.ShowNotice}>
                {this.props.error ? (
                  error
                ) : (
                  <React.Fragment>
                    <h3>You are almost there!</h3>
                    <p>
                      Your request has successfuly been sent! Please wait for an
                      administrator to approve it. Once approved the clinic will
                      be available inside your toggle menu on the left.
                    </p>
                  </React.Fragment>
                )}
                <p>
                  Please visit <Link to="/faq">FAQ</Link> if you have any
                  questions.
                </p>
                <Button clicked={() => this.setState({ showNotice: false })}>
                  ok
                </Button>
              </div>
            </Modal>
          ) : this.state.showNotice && !this.state.isJoin ? (
            <Modal show={this.state.showNotice}>
              <div className={classes.ShowNotice}>
                <React.Fragment>
                  <h3>You have successfuly created a clinic</h3>
                  <p>
                    Congratulations! You are now an administrator of this
                    clinic.
                  </p>
                  <ul>
                    <span>As an administrator you can:</span>
                    <li>Create and manage members</li>
                    <li>Remove members from the clinic</li>
                  </ul>
                </React.Fragment>

                <p>
                  Please visit <Link to="/faq">FAQ</Link> if you have any
                  questions.
                </p>
                <Button clicked={() => this.setState({ showNotice: false })}>
                  ok
                </Button>
              </div>
            </Modal>
          ) : null}
          {form}
          {!this.state.showForm ? (
            <React.Fragment>
              <div
                className={classes.Button}
                onClick={this.showJoinFormHandler}
              >
                <span>Join a clinic</span>
                <p>Know your clinic's ID? Use it here!</p>
              </div>
              <div
                className={classes.Button}
                onClick={this.showCreateFormHandler}
              >
                <span>Create a clinic</span>
                <p>Make a clinic, manage all staff members!</p>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
const mapPropsToState = (state) => {
  return {
    token: state.auth.token,
    error: state.newclinic.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onJoin: (isJoin, data, token) =>
      dispatch(actions.joinClinic(isJoin, data, token)),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(NewClinic);

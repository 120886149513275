import classes from './Topics.module.css';
import React from 'react';
import TopicCard from './TopicCard/TopicCard';
import { useRouteMatch } from 'react-router';

const Topics = (props) => {
  const { url } = useRouteMatch();
  const topics = props.topics.map(({ title, id }) => {
    return (
      <TopicCard key={id} path={`${url}/${id}`}>
        {title}
      </TopicCard>
    );
  });
  return (
    <div className={classes.Topics}>
      <h2>Topics</h2>
      {topics}
    </div>
  );
};

export default Topics;

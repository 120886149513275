import React, { Component } from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import * as actions from '../../../store/actions/index';

import classes from './ViewSoup.module.css';
import ViewSection from './ViewSection/ViewSection';
import Button from '../../../components/UI/Button/Button';

import HistoryPDF from '../PDF/HistoryPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
class ViewSoup extends Component {
  componentDidUpdate = (prevState) => {
    if (this.props.soupId !== prevState.soupId) {
      this.props.onFetchSoup(this.props.soupId);
    }
  };

  render() {
    let soup = null;
    if (this.props.soup && !this.props.loading) {
      const {
        id,
        date,
        start_time,
        end_time,
        group,
        telehealth,
        supervisor_id,
        staff_member_id,
        starting,
        conducting,
        ending,
        additional,
        // total,
        json,
      } = this.props.soup;
      const startTime = moment(start_time).format('hh:mm A').toString()
      const endTime = moment(end_time).format('hh:mm A').toString()
      const soupDate = moment(date).format('MMM D, YYYY').toString()
      const category = ['starting', 'main', 'ending', 'additional', 'audit'];
      const soupCategories = Object.keys(json);
      const score = [starting, conducting, ending, additional];
      const soupSections = category.map((soupSection, i) => {
        return soupCategories.includes(soupSection) && (
          <ViewSection
            key={soupSection + i}
            category={soupSection}
            questions={json}
            score={score[i]}
          />
        );
      });
      soup = (
        <div className={classes.ViewSoup}>
          <div className={classes.Controls}>
           <PDFDownloadLink
              document={
                <HistoryPDF
                  soup={this.props.soup}
                  soupSections={soupCategories}
                  start={startTime}
                  end={endTime}
                />
              }
            >
              <Button type="button">download(PDF)</Button>
            </PDFDownloadLink>
      

            <NavLink
              to={{
                pathname: '/soupervision/' + staff_member_id,
                name: staff_member_id,
                controls: json,
                edit: true,
                date: soupDate,
                startTime: start_time,
                endTime: end_time,
                search: 'edit=true',
                soupId: id,
                userId: this.props.userId,
                creatorId: supervisor_id,
              }}
            >
              {parseInt(this.props.userId) === supervisor_id ? (
                <Button btnType="Transparent">
                  Edit
                </Button>
              ) : null}
            </NavLink>

            {/* <Button btnType="Transparent">Print(PDF)</Button>
            <Button>Delete</Button> */}
          </div>
          <header>
            <div>
              <span>Supervisor: {supervisor_id}</span>
              <span>Therapist: {staff_member_id}</span>
              <span>
                Note: Session was conducted{' '}
                {telehealth ? 'remotely' : 'in-person'} and in{' '}
                {group ? 'group' : 'solo'}
              </span>
            </div>
            <div>
              <span>Date: {soupDate}</span>
              <span>Session started at: {startTime}</span>
              <span>Session ended at: {endTime}</span>
            </div>
          </header>
          <section>
            <table>{soupSections}</table>
          </section>
        </div>
      );
    }
    return <div>{soup}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    soup: state.history.soup,
    userId: state.auth.userId,
    loading: state.history.loading,
  };
};

const mapDispatchToPropst = (dispatch) => {
  return {
    onFetchSoup: (soupId) => dispatch(actions.fetchSoup(soupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToPropst)(ViewSoup);
